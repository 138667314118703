import CorporateContactJson from './CorporateContactJson';
import InitialSeo from './InitialSeo';
import SocialProfileJson from './SocialProfileJson';

const AppSeo = () => {
  return (
    <>
      <InitialSeo />
      <CorporateContactJson />
      <SocialProfileJson />
    </>
  );
};

export default AppSeo;
