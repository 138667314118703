/**
 * Make it easier to create style variants within styled-components
 * to avoid creating complex ternaries
 *
 * @example
 * // returns '50rem'
 * variant('large', {
 *   large: `50rem`,
 *   medium: `30rem`,
 *   small: `10rem`,
 * });
 *
 * @param name the name of the variant you want to access
 * @param variants an object that represents all possible variants
 * @returns the value of the variant
 */
export const variant = (
  name: string | number,
  variants: Record<string, string | number>,
): string | number | undefined => {
  return variants[name];
};
