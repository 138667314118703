const HOME_ROUTE = '/';

//  LINKS ATTENDANCE
const GENERAL_QUESTIONS =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331142558099-D%C3%BAvidas-Gerais';
const FIND_YOUR_SIZE =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331161233171-Encontre-seu-Tamanho';
const ATTENDANCE_DELIVERIES =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5330991677203-Entrega';
const ATTENDANCE_REQUESTS =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331072633491-Pedidos';
const EXCHANGES_AND_RETURNS =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5330983405075-Trocas-e-devolu%C3%A7%C3%B5es';
const ATTENDANCE_PAYMENTS =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331065761427-Pagamentos';
const ATTENDANCE_PRODUCTS =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331044797075-Produtos';
const ATTENDANCE_CORPORATE =
  'https://atendimento.nike.com.br/hc/pt-br/sections/5331067989267-Corporativo';
const ATTENDANCE_ROUTE = 'https://atendimento.nike.com.br/hc/pt-br';

const GEO_SELECTION_LANGUAGE_ROUTE = 'https://www.nike.com/?geoselection=true';
const INSTAGRAM_ROUTE = 'https://www.instagram.com/nike/';
const YOUTUBE_ROUTE = 'https://www.youtube.com/user/nike';
const FACEBOOK_ROUTE = 'https://www.facebook.com/nike';
const SUSTAINABILITY_ROUTE = 'https://www.nike.com.br/sc/sustentabilidade';
const MADE_TO_PLAY_ROUTE = 'https://www.nike.com.br/sc/feito-pra-jogar';
const PRIVACY_POLICY_ROUTE =
  'https://atendimento.nike.com.br/hc/pt-br/articles/5342267459731-Pol%C3%ADtica-de-Privacidade';
const TERMS_OF_SERVICE_ROUTE =
  'https://atendimento.nike.com.br/hc/pt-br/articles/5342261426451-Termos-de-Servi%C3%A7o';
const MY_REQUESTS = '/minhas-solicitacoes';
const REQUEST_DETAILS = '/minhas-solicitacoes/detalhes';
const RETURN_ROUTE = '/devolucao-de-produto';
const AVAILABLE_RETURN_STORES_ROUTE = '/lojas-habilitadas';
const RETURN_MOTIVE_ROUTE = '/devolucao-de-produto/motivo';
const RETURN_REFUND_ROUTE = '/devolucao-de-produto/reembolso';
const REQUEST_SUMMARY_ROUTE = '/devolucao-de-produto/resumo';
const REQUEST_MADE_ROUTE = '/devolucao-de-produto/solicitacao-realizada';

const NIKE = 'https://www.nike.com.br';
const NIKE_COMMUNICATION_PREFERENCE =
  'https://www.nike.com/pt/member/settings/communication-preferences';

/**
This function is used to direct the route to somewhere. For example, home link.
 */

export const getCoreRoutes = () => ({
  attendance: ATTENDANCE_ROUTE,
  facebook: FACEBOOK_ROUTE,
  geoSelectionLanguage: GEO_SELECTION_LANGUAGE_ROUTE,
  home: HOME_ROUTE,
  instagram: INSTAGRAM_ROUTE,
  madeToPlay: MADE_TO_PLAY_ROUTE,
  myRequests: MY_REQUESTS,
  requestDetails: REQUEST_DETAILS,
  returnMotive: RETURN_MOTIVE_ROUTE,
  returnRefund: RETURN_REFUND_ROUTE,
  returnRoute: RETURN_ROUTE,
  storesRoute: AVAILABLE_RETURN_STORES_ROUTE,
  privacyPolicy: PRIVACY_POLICY_ROUTE,
  sustainability: SUSTAINABILITY_ROUTE,
  termsOfService: TERMS_OF_SERVICE_ROUTE,
  youTube: YOUTUBE_ROUTE,
  requestSummary: REQUEST_SUMMARY_ROUTE,
  requestMade: REQUEST_MADE_ROUTE,
  generalQuestions: GENERAL_QUESTIONS,
  findYourSize: FIND_YOUR_SIZE,
  attendanceDeliveries: ATTENDANCE_DELIVERIES,
  attendanceRequests: ATTENDANCE_REQUESTS,
  exchangesAndReturns: EXCHANGES_AND_RETURNS,
  attendancePayments: ATTENDANCE_PAYMENTS,
  attendanceProducts: ATTENDANCE_PRODUCTS,
  attendanceCorporate: ATTENDANCE_CORPORATE,
  communicationPreference: NIKE_COMMUNICATION_PREFERENCE,
  nike: NIKE,
});
